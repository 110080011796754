@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: 'Poppins';
  iframe#webpack-dev-server-client-overlay{display:none!important}

  /* 👇️ or use Operating system default fonts 👇️ */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}