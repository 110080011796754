.scrollbar-none {
  scrollbar-width: none;
}

.scrollbar-none::-webkit-scrollbar {
  width: 0.5em;
}

.scrollbar-none::-webkit-scrollbar-thumb {
  background-color: transparent;
}

table {
  background-color: "#F6F7F8";
}

.list-auto-height {
  height: calc(100vh - 380px - 209px);
  padding-bottom: 100px;
}

.list-auto-height-mobile {
  height: calc(100vh - 194px - 209px);
  padding-bottom: 100px;
}

.table-row {
  transition: background-color 0.1s;
}

.table-row:hover {
  background-color: #E7E7F0;
  /* Change this to your desired hover color */
}